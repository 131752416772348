<script>
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {userService} from "@/helpers/fakebackend/user.service";
import {ToCSV} from "@/helpers/ToCSV";
import {requestMessage} from "@/helpers/request-message";
import {globalVariable} from "@/helpers/global-variables";
/**
 * Form-element component
 */
export default {
  page: {
    title: "Benutzerverwaltung",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Benutzerverwaltung",
      listView: true,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Benutzerverwaltung",
          active: true,
        },
      ],
      users: [],
      filteredUsers: [],
      user: {},
      loggedUser: {},
      tableTitle: "Advanced Table",
      tableItems: [
        {
          text: "Tables",
          href: "/"
        },
        {
          text: "Advanced",
          active: true
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "E-mail", sortable: true },
        { key: "phone", label: "Telefon", sortable: true },
        { key: "account_admin", label: "Account-Admin", sortable: true },
        { key: "cloud_admin", label: "Cloud-Admin", sortable: true },
        { key: "status", label: "Aktionen",  sortable: false },
      ],
      globalMessage: {message: "", type: "danger"},
      isEditUser: false,
      isLoading: true,
    };
  },
  created: function () {
    this.loggedUser = JSON.parse(localStorage.getItem("user_infos"));
  },
  computed: {
    rows() {
      return this.users.length;
    }
  },
  mounted() {
    userService.getAll().then(data => {
      this.users = data.content;
      this.filteredUsers = data.content;
      this.users.forEach((user)=>{
        user.deleting = false;
        user.disabled = true;
        user.success = null;
      });
      this.isLoading = false;
    }).catch(data => {
      const message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      Swal.fire(message, '', "warning");
      this.isLoading = false;
    });
    this.totalRows = this.items.length;
    this.reduceColumnForSmallScreen(600);
  },
  methods: {

    jsonToArray(data){
      let tab = [];
      for(let item in data){
        tab.push(data[item]);
      }
      return tab;
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.filteredUsers = filteredItems;
    },

    toggleBlockUser(user){
        let message = "";
        if(user.status === 1){
          user.status = 4;
          userService.changeUserStatus(user.uuid, userService.LOCK_USER).then((data) => {
              user.status = 0;
              Swal.fire(requestMessage[data.code].de, '', 'success');
          }).catch((data)=>{
            user.status = 1;
            message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
            Swal.fire(message, '', "warning");
          });
        }else{
          user.status = 4;
          userService.changeUserStatus(user.uuid, userService.UNLOCK_USER).then((data) => {
            user.status = 1;
            Swal.fire(requestMessage[data.code].de, '', 'success');
          }).catch((data)=>{
            user.status = 0;
            message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
            Swal.fire(message, '', "warning");
          });
        }
    },

    extendInvitationUser(user){
        let message = "";
        user.sendingInvitation = true;
        this.$refs.table.refresh();
        userService.extendInvitation(user.uuid).then(() => {
            user.sendingInvitation = false;
            this.$refs.table.refresh();
            let message = "Erfolgreiche Verlängerung der Einladung.";
            Swal.fire("Gesendet", message, "success");
        }).catch((data)=>{
          user.sendingInvitation = false;
          this.$refs.table.refresh();
          message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
          Swal.fire("Gesendet", message, "warning");
        });
    },

    deleteUser(user, index){
      user.deleting = false;
      userService.deleteUser(user.uuid).then(data => {
        if(data.status === globalVariable.api.SUCCESS_STATUS) {
          const message = "Der Benutzer wurde erfolgreich gelöscht.";
          this.users.splice(index, 1);
          this.deleteUserFromTable(user.uuid);
          Swal.fire("Gelöscht", message, "success");
          this.$refs.table.refresh();
        }
      }).catch((data)=>{
        user.deleting = false;
        this.$refs.table.refresh();
        const message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
        Swal.fire("Gelöscht", message, "warning");
      });
    },

    deleteUserFromTable(index){
      this.users.splice(index, 1);
    },
    confirmDelete(user, index) {
      let self = this;
      user.deleting = true;
      this.$refs.table.refresh();
      Swal.fire({
        title: "Sind Sie sicher ?",
        text: "Das Löschen eines Nutzers ist unwideruflich",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Ja",
        cancelButtonText: "Abbrechen"
      }).then(result => {
        if (result.value) {
          self.deleteUser(user, index);
        }else{
          user.deleting = false;
          this.$refs.table.refresh();
        }
      });
    },
    reduceColumnForSmallScreen(minSize){
      if (screen.availWidth <= minSize){
        this.fields = [
          { key: "name", label: "Name", sortable: true },
          { key: "status", label: "Zustand",  sortable: true },
        ]
      }
    },
    exportTableAsCSV(){
      let csv = new ToCSV(this.filteredUsers);
      csv.createCSVFile();
    },
    changeUserView(e){
      e.preventDefault();
      if (this.users.length === 0)
        this.listView = true
      else
        this.listView = !this.listView;
    },
    onSaveUser(event, user) {
      event.preventDefault();
      user.success = null;
      if (user.disabled){
        user.disabled = false;
        this.$forceUpdate();
        return;
      }
      user.loading = true;
      this.$forceUpdate();

      let request = null;
      if (user.uuid)
        request = userService.editUser(user)
      else {
        request = userService.addUser(user);
        user.status = 2;
      }
      request.then((data)=>{
        if (data.content)
          user.uuid = data.content.uuid;
        user.disabled = true;
        user.loading = false;
        Swal.fire('Nutzer erfolgreich gespeichert', '', 'success')
        this.$forceUpdate();
      }).catch((data)=>{
        user.loading = false;
        Swal.fire(requestMessage[data.code].de, '', 'warning')
        this.$forceUpdate();
      });
    },

    onRemoveUser(e, user){
      e.preventDefault();
      user.success = null;
      user.loading = true;
      this.$forceUpdate();
      Swal.fire({
        title: "Sie sind sicher?",
        text:"Das Löschen eines Nutzers ist unwiderruflich",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00e200",
        cancelButtonColor: "#ff3d60",
        confirmButtonText: "Ja",
        cancelButtonText: "Nein"
      }).then(result => {
        if (result.value) {
          if (user.uuid){
            userService.deleteUser(user.uuid)
                .then((data)=>{
                  this.users = this.removeItemFromArray(this.users, user);
                  Swal.fire(requestMessage[data.code].de, '', 'success');
                })
                .catch((data)=>{
                  const message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
                  user.loading = false;
                  Swal.fire(message, '', 'warning')
                  this.$forceUpdate();
                })
          }else
            this.users = this.removeItemFromArray(this.users, user)
        }else{
          user.loading = false;
          this.$forceUpdate();
        }
      });
    },
    removeItemFromArray(array, item){
      const tempUsers = [];
      for (let i=0; i < array.length; i++){
        if (item !== array[i]){
          tempUsers.push(array[i]);
        }
      }
      return tempUsers;
    },
    addUser(email, name, isAccountAdmin, isCloudAdmin){
      this.listView = false;
      const tempUserList = [{
        name: name??"",
        phone: "",
        email: email??"",
        isAccountAdmin: isAccountAdmin??0,
        isCloudAdmin: isCloudAdmin??0,
        success: null,
        error: null,
      }];
      this.users = tempUserList.concat(this.users);
    },
    onCloseUser(e, user){
      e.preventDefault();
      this.users = this.removeItemFromArray(this.users, user)
      if (this.users.length === 0)
        this.listView = true;
    },
    toggleRoles(user){
      user.isAccountAdmin = user.isAccountAdmin===1?0:1;
      this.$forceUpdate();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="isLoading" rounded="sm">
      <div class="row no-gutters">
        <div class="col-md-12">
          <div class="cardx">
            <div class="card-body">
              <div class="row no-gutters">
                <div class="col-12 pl-0">
                  <h2 class="mb-4">Ihre Benutzer</h2>
                  <div class="mt-4"><b-button size="sm" class="btn-primary btn-plus" v-on:click="addUser()"><span><i class="dripicons-plus"></i></span>hinzufügen</b-button></div>
                </div>
              </div>
              <div class="row no-gutters mt-3">
                <div class="col-sm-12 col-md-6 pl-0">
                  <div id="tickets-table_length" class="dataTables_length">
                    <span>Anzeigen</span>
                    <label class="d-inline-flex align-items-center">
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                    </label>
                    <span>pro Seite</span>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <a :class="!listView?'text-primary':''" href="#" v-on:click="changeUserView"><i class="ri-windows-fill fa-2x"></i></a>
                    <a :class="listView?'text-primary':''" href="#" v-on:click="changeUserView"><i class="ri-list-unordered fa-2x"></i></a>
                  </div>
                </div>
              </div>
              <!-- Table -->
              <div class="row no-gutters" v-show="listView">
                <div class="col-md-12 pl-0">
                  <div class="table-responsive mb-0">
                    <b-table
                        :items="users"
                        :fields="fields"
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        ref="table"
                        class="table-hover"
                    >
                      <template #cell(account_admin)="row">
                        <div class="custom-control custom-checkbox">
                          <input
                              type="checkbox"
                              class="custom-control-input"
                              required
                              :disabled="row.item.disabled"
                              :checked="row.item.isAccountAdmin===1"
                              :id="'account_admin_'+row.index"
                              v-on:change="(e)=>toggleRoles(row.item)"
                          />
                          <label
                              class="custom-control-label"
                              :for="'account_admin_' + row.index"
                          ></label>
                        </div>
                      </template>
                      <template #cell(cloud_admin)="row">
                        <div class="custom-control custom-checkbox">
                          <input
                              type="checkbox"
                              class="custom-control-input"
                              :disabled="row.item.disabled"
                              :checked="row.item.isCloudAdmin===1"
                              :id="'cloud_admin_'+row.index"
                              v-on:change="(e)=>row.item.isCloudAdmin = row.item.isCloudAdmin===1?0:1"
                          />
                          <label
                              class="custom-control-label"
                              :for="'cloud_admin_' + row.index"
                          ></label>
                        </div>
                      </template>

                      <template #cell(name)="row">
                        <span v-show="row.item.disabled">{{ row.item.name }}</span>
                        <span v-show="!row.item.disabled">
                          <input
                              type="text"
                              class="form-control"
                              :id="'email_'+row.index"
                              placeholder="Name"
                              v-model="row.item.name"
                              :disabled="row.item.disabled"
                          />
                        </span>
                      </template>

                      <template #cell(email)="row">
                        <span v-show="row.item.disabled">{{ row.item.email }}</span>
                        <span v-show="!row.item.disabled">
                          <input
                              type="email"
                              class="form-control"
                              :id="'email_'+row.index"
                              placeholder="E-Mail-Adresse"
                              v-model="row.item.email"
                              :disabled="row.item.disabled"
                          />
                        </span>
                      </template>
                      <template #cell(phone)="row">
                        <span v-show="row.item.disabled">{{ row.item.phone }}</span>
                        <span v-show="!row.item.disabled">
                          <input
                              type="tel"
                              class="form-control"
                              :id="'email_phone_'+row.index"
                              placeholder="Telefon"
                              v-model="row.item.phone"
                              :disabled="row.item.disabled"
                          />
                        </span>
                      </template>
                      <template #cell(status)="row">
                        <b-overlay :show="row.item.loading">
                          <div class="action-buttons" v-if="loggedUser.uuid !== row.item.uuid">
                            <b-button size="sm" @click="toggleBlockUser(row.item)"
                                      class="mr-2 btn btn-white" v-b-tooltip.hover
                                      title="Benutzer sperren"
                                      v-if="row.item.status === 1">
                              <i class="ri-lock-2-line"></i>
                            </b-button>
                            <b-button size="sm" @click="toggleBlockUser(row.item)"
                                      class="mr-2 btn btn-white" v-b-tooltip.hover
                                      title="Benutzer entsperren"
                                      v-if="row.item.status === 0">
                              <i class="ri-lock-unlock-line"></i>
                            </b-button>
                            <b-button size="sm" class="mr-2 btn btn-white" v-b-tooltip.hover
                                      v-if="row.item.status === 4">
                              <i class=" ri-loader-line"></i>
                            </b-button>

                            <b-button size="sm" @click="toggleBlockUser(row.item)"
                                      class="mr-2 btn btn-warning" v-b-tooltip.hover
                                      v-if="row.item.sendingInvitation">
                              <i class=" ri-loader-line"></i>
                            </b-button>
                            <b-button size="sm" @click="extendInvitationUser(row.item)"
                                      class="mr-2 btn btn-warning" v-b-tooltip.hover
                                      title="Einladung verlängern"
                                      v-if="row.item.uuid && row.item.status === 2 && !row.item.sendingInvitation">
                              <i class="ri-mail-send-fill"></i>
                            </b-button>

                            <b-button size="sm" v-on:click="(e) => {onSaveUser(e, row.item);row.item.disabled = false;}"
                                      class="mr-2 btn btn-white"
                                      v-if="row.item.status !== 2"
                                      v-b-tooltip.hover title="Benutzer bearbeiten">
                              <i class="ri-edit-2-line" v-if="row.item.disabled"></i><i v-else class="ri-save-2-line"></i>
                            </b-button>
                            <a size="sm" class="btn btn-danger btn-sm"
                               v-on:click="(e) => {onCloseUser(e, row.item);}" v-if="!row.item.uuid">
                              <i class="ri-close-line"></i>
                            </a>
                            <b-button v-if="row.item.uuid" size="sm" @click="(e)=>onRemoveUser(e, row.item)" class="mr-2 btn btn-danger"
                                      v-b-tooltip.hover title="Benutzer löschen">
                              <i class="ri-delete-bin-5-line" v-if="!users[row.index].deleting"></i>
                              <i class=" ri-loader-line" v-if="users[row.index].deleting"></i>
                            </b-button>
                          </div>
                        </b-overlay>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
              <div class="row no-gutters" v-show="listView">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-show="!listView">
                <div class="col-md-4 mb-4 pl-0" v-for="(user,index) in users" v-bind:key="index">
<!--                  <user-card :logged-user="loggedUser" :index="index" :user="user" v-bind:key="index"
                    v-on:close-user="onCloseUser"
                    v-on:save-user="onSaveUser"
                    v-on:remove-user="onRemoveUser"
                    v-on:toggle-user-block="toggleBlockUser"
                    v-on:extend-invitation-user="extendInvitationUser"
                  >
                  </user-card>-->
                  <b-card>
                    <b-overlay :show="user.loading">
                      <b-form class="mr-2">
                        <div class="mb-4">
                          <h5 class="d-flex justify-content-between align-items-center">
                            <b><span v-if="!user.uuid">Neuer</span> Benutzer</b>
                            <a type="button" class="btn btn-outline text-dark"
                               v-on:click="(e) => {onCloseUser(e, user);}" v-if="!user.uuid">
                              <i class="ri-close-line ri-2x"></i>
                            </a>
                            <span class="invisible btn btn-outline text-dark" v-else><i class="ri-close-line ri-2x"></i></span>
                          </h5>
                        </div>
                        <div class="row mb-2">
                          <div class="col-12">
                            <b>Berechtigung*</b>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 col-12 mb-3">
                            <div class="custom-control custom-checkbox">
                              <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="'account_'+index"
                                  name="roles"
                                  required
                                  :disabled="user.disabled"
                                  :checked="user.isAccountAdmin===1"
                                  v-on:change="(e)=>user.isAccountAdmin = user.isAccountAdmin===1?0:1"
                              />
                              <label
                                  class="custom-control-label font-weight-normal"
                                  :for="'account_'+index"
                              >Account-Admin</label>
                            </div>
                          </div>
                          <div class="col-md-6 col-12 mb-3">
                            <div class="custom-control custom-checkbox">
                              <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="'cloud_'+index"
                                  name="roles"
                                  required
                                  :disabled="user.disabled"
                                  :checked="user.isCloudAdmin===1"
                                  v-on:change="(e)=>user.isCloudAdmin = user.isCloudAdmin===1?0:1"
                              />
                              <label
                                  class="custom-control-label font-weight-normal"
                                  :for="'cloud_'+index"
                              >Cloud-Admin</label>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group auth-form-group-custom mb-4">
                              <label :for="'name_'+index">Name*</label>
                              <input
                                  type="text"
                                  class="form-control"
                                  :id="'name_'+index"
                                  placeholder="Name"
                                  v-model="user.name"
                                  :disabled="user.disabled"
                                  required
                              />
                            </div>
                            <div class="form-group auth-form-group-custom mt-2">
                              <label :for="'email_phone_'+index">Erreichbarkeit</label>
                              <input
                                  type="tel"
                                  class="form-control"
                                  :id="'email_phone_'+index"
                                  placeholder="Telefon"
                                  v-model="user.phone"
                                  :disabled="user.disabled"
                              />
                              <input
                                  type="email"
                                  class="form-control mt-2"
                                  :id="'email_phone_'+index"
                                  placeholder="E-Mail*"
                                  v-model="user.email"
                                  :disabled="user.disabled"
                              />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row mt-3">
                              <div class="col-6">
                                <button :disabled="loggedUser.uuid === user.uuid" type="submit" class="btn btn-primary" v-on:click="(e) => {onSaveUser(e, user);user.disabled = false;}">
                                  <span v-show="user.disabled">Ändern</span><span v-show="!user.disabled">Speichern</span>
                                </button>
                              </div>
                              <div class="col-6">
                                <div class="text-right" v-if="loggedUser.uuid !== user.uuid">
                                  <b-button size="sm" @click="toggleBlockUser(user)"
                                            class="mr-2 btn btn-white" v-b-tooltip.hover
                                            title="Benutzer sperren"
                                            v-if="user.status === 1">
                                    <i class="ri-lock-2-line"></i>
                                  </b-button>
                                  <b-button size="sm" @click="toggleBlockUser(user)"
                                            class="mr-2 btn btn-white" v-b-tooltip.hover
                                            title="Benutzer entsperren"
                                            v-if="user.status === 0">
                                    <i class="ri-lock-unlock-line"></i>
                                  </b-button>
                                  <b-button size="sm" class="mr-2 btn btn-white" v-b-tooltip.hover
                                            v-if="user.status === 4">
                                    <i class=" ri-loader-line"></i>
                                  </b-button>

                                  <b-button size="sm" class="mr-2 btn btn-warning" v-b-tooltip.hover
                                            v-if="user.sendingInvitation === true">
                                    <i class=" ri-loader-line"></i>
                                  </b-button>
                                  <b-button size="sm" @click="extendInvitationUser(user)"
                                            class="mr-2 btn btn-warning" v-b-tooltip.hover
                                            title="Einladung verlängern"
                                            v-if="user.uuid && user.status === 2 && !user.sendingInvitation">
                                    <i class="ri-mail-send-fill"></i>
                                  </b-button>
                                  <b-button v-if="user.uuid" size="sm" @click="(e)=>onRemoveUser(e, user)" class="mr-2 btn btn-danger"
                                            v-b-tooltip.hover title="Benutzer löschen">
                                    <i class="ri-delete-bin-5-line" v-if="!user.deleting"></i>
                                    <i class=" ri-loader-line" v-if="user.deleting"></i>
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-form>
                    </b-overlay>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
<style scoped>
.action-buttons .btn{
  padding: 4px 10px;
}
.action-buttons .btn-white{
  background-color: #ebebed;
  color: #0e980e!important;
  border-width: 0;
}
.custom-checkbox .custom-control-label::before {
  background: #ececee;
  border: none;
}

.card{
  background-color: #ebebed!important;
}
.card input{
  background-color: #fff!important;
}
.custom-checkbox .custom-control-label::before{
  background-color: #fff;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00e200;
  background-color: #00e200;
}
</style>